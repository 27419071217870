
// @ is an alias to /src
import TextButton from "@/components/atomics/TextButton.vue";
import ErrorTile from "@/components/atomics/ErrorTile.vue";
import ValidationTextField from "@/components/atomics/ValidationTextField.vue";
import Notification from "@/components/atomics/Notification.vue";
import AvatarCircle from "@/components/organisms/AvatarCircle.vue";
import { MemberRepository, RepositoryFactory } from "@/lib/https";
import { useForm } from "vee-validate";
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  toRefs,
  watch,
  inject,
} from "vue";
import router from "@/router";
import { isEmptyObject, resizeImage } from "@/lib/utility/common";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "Profile",
  components: {
    TextButton,
    ValidationTextField,
    Notification,
    AvatarCircle,
  },
  setup(props) {
    const isMobile = inject("isMobile");
    const state = reactive({
      email: "",
      firstname: "",
      lastname: "",
      phoneNumber: "",
      position: "",
      profilePicture: "",

      enableNextButton: false,
      enablePrevButton: true,
    });

    const avatarUpload = ref();

    const { getProfile, updateProfile } = profileMethods();

    const goNext = () => {
      router.push("/onboarding/authentification");
    };

    const { values, errors, submitForm } = useForm();
    const store = useStore();
    const { t } = useI18n();

    watch(
      () => errors.value,
      (val) => {
        state.enableNextButton = isEmptyObject(val);
      }
    );

    const isFullRequireField = computed(() => {
      const { firstname, lastname } = state;

      return firstname != "" && lastname != "";
    });

    const submit = () => {
      submitForm().then(async () => {
        if (!isEmptyObject(errors.value)) return;

        const formData = new FormData();

        if (avatarUpload.value) {
          formData.append("profilePicture", avatarUpload.value);
        }

        formData.append("firstName", values["firstname"] ?? "");
        formData.append("lastName", values["lastname"] ?? "");
        formData.append("position", values["position"] ?? "");
        formData.append("phoneNumber", values["phoneNumber"] ?? "");

        try {
          if (await updateProfile(formData)) {
            goNext();
          }
        } catch (e: any) {
          if (e.data) {
            store.commit(
              "notification/showErrorNotification",
              e.data.message || ""
            );
          }
        }
      });
    };

    const uploadImage = async (e: any) => {
      const sourceFile = e.target.files || e.dataTransfer.files;
      const file = sourceFile[0];
      if (file.size > 200000) {
        store.commit(
          "notification/showErrorNotification",
          t("errors.errorOversizeAvatar")
        );
        return;
      }
      const imageResized = (await resizeImage(file)) as any;

      avatarUpload.value = imageResized.fileUpload;
      state.profilePicture = imageResized.base64;
    };

    const route = useRoute();

    if (route.query.isCompanyCreating === "false") {
      state.enablePrevButton = false;
    }

    onMounted(async () => {
      const profileValues = await getProfile();
      state.email = profileValues.email;
      state.firstname = profileValues.firstName ? profileValues.firstName : "";
      state.lastname = profileValues.lastName ? profileValues.lastName : "";
      state.phoneNumber = profileValues.phoneNumber;
      state.position = profileValues.position;
      state.profilePicture = profileValues.profilePicture
        ? process.env.VUE_APP_API_CLOUD_URL +
          profileValues.profilePicture +
          `?${Date.now()}`
        : "";
    });

    return {
      ...toRefs(state),
      avatarUpload,
      submit,
      isFullRequireField,
      resizeImage,
      uploadImage,
      isMobile,
    };
  },
  methods: {
    uploadFile(e: any) {
      // if (e.pointerType === "mouse") {
        let fileInput = this.$refs.file as HTMLInputElement;
        fileInput.click();
      // }
    },

    goPrev() {
      this.$router.push({ name: "Organization" });
    },
  },
});

const profileMethods = () => {
  const { getProfile, updateProfile } =
    RepositoryFactory.getRepository<MemberRepository>(MemberRepository);

  return {
    getProfile,
    updateProfile,
  };
};
